import { Wage } from 'src/types/EmployeeCard';
import { inputSchema } from 'src/validation/schemas/inputSchema';

export const hasAmountChanged = (amount: number, newAmount: string) => {
  if (amount === null && newAmount === '') {
    return false;
  }
  if (amount === 0 && newAmount === '') {
    return false;
  }
  if (amount?.toString().replaceAll('.', ',') !== newAmount && !isNaN(Number(newAmount.replaceAll(',', '.')))) {
    return true;
  } else {
    return false;
  }
};

export const getInvalidWageInputs = (wage: Wage, hoursInput: string, amount: string, payedAmount: string) => {
  const quantityValueValidation = inputSchema.validate({
    positiveIntegerString: hoursInput,
  });
  const amountValidation = inputSchema.validate({
    amount2DecAllowNegative: amount,
  });
  const payedAmountValidation = inputSchema.validate({
    amount2DecAllowNegative: payedAmount,
  });

  const isHourlySalary: boolean = wage?.wageType === 'HOURLY_SALARY';

  const hasChangedAmountInput: boolean =
    hasAmountChanged(wage.amount, amount) || hasAmountChanged(wage.payedAmount, payedAmount);

  const hasChangedHoursInput = isHourlySalary
    ? hasAmountChanged(wage.quantityValue, hoursInput)
    : hasChangedAmountInput;

  const hasAmountValidationError: boolean = !!amountValidation.error || !!payedAmountValidation.error;

  const hasQuantityValueValidationError = isHourlySalary ? !!quantityValueValidation.error : false;

  if (
    (!hasChangedAmountInput && !hasChangedHoursInput) ||
    hasAmountValidationError ||
    hasQuantityValueValidationError
  ) {
    return true;
  } else {
    return false;
  }
};
