import { Button, Card, CardAction, Modal, Select, Option } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { CreatePolicyBenefitRequest } from 'src/types/policy/request/CreatePolicyBenefitRequest';
import { useCreatePolicyBenefit } from 'src/service/policy/CreatePolicyBenefit';
import { createOptions, createOptionsFromList } from 'src/util/OptionUtil';
import { PolicyEmploymentGroup } from 'src/types/policy/PolicyEmploymentGroup';
import './create-benefit-modal.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_BENEFIT_TYPES } from 'src/graphql/schema/query/policy/GetBenefitTypes';
import { PolicyBenefitType } from 'src/types/policy/PolicyBenefitType';
import TextInput from 'src/components/Common/TextInput';

interface CreateBenefitModalProps {
  open: boolean;
  employerId: string;
  policyId: string;
  employmentGroups: PolicyEmploymentGroup[];
  onClose: () => void;
}

const CreateBenefitModal: React.FunctionComponent<CreateBenefitModalProps> = ({
  open,
  employerId,
  policyId,
  employmentGroups,
  onClose,
}) => {
  const { t } = useTranslation();
  const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);
  const [createBenefitRequest, setCreateBenefitRequest] = useState<CreatePolicyBenefitRequest>({
    policyId: policyId,
    policyEmploymentGroupIds: [],
    benefitType: '',
    name: '',
    description: '',
  });
  const createPolicyBenefit = useCreatePolicyBenefit();

  const employmentGroupOptions: Option<string>[] = createOptionsFromList(employmentGroups, 'id', 'employmentGroupName');

  const validateAndSetCreateButtonDisabled = (currentRequest: CreatePolicyBenefitRequest) => {
    setCreateButtonDisabled(
      currentRequest.name.length < 3 ||
        currentRequest.benefitType.length < 1 ||
        currentRequest.policyEmploymentGroupIds.length < 1 ||
        currentRequest.name.length > 64,
    );
  };

  const handleBenefitName = (event: ChangeEvent<HTMLInputElement>) => {
    setCreateBenefitRequest((prev) => {
      return { ...prev, name: event.target.value };
    });
    validateAndSetCreateButtonDisabled({ ...createBenefitRequest, name: event.target.value });
  };

  const handleOnClose = () => {
    setCreateButtonDisabled(false);
    onClose();
  };

  const handleCreateBenefit = async () => {
    setCreateButtonDisabled(true);
    createPolicyBenefit(employerId, createBenefitRequest).finally(() => {
      handleOnClose();
    });
  };

  const handleBenefitTypeChange = (option: Option<string>) => {
    setCreateBenefitRequest((prev): CreatePolicyBenefitRequest => {
      return { ...prev, benefitType: option.value };
    });
    validateAndSetCreateButtonDisabled({ ...createBenefitRequest, benefitType: option.value });
  };

  const handleEmploymentGroupChange = (options: Option<string>[]) => {
    const ids = options.map((option) => option.value);
    setCreateBenefitRequest((prev): CreatePolicyBenefitRequest => {
      return { ...prev, policyEmploymentGroupIds: [...ids] };
    });
    validateAndSetCreateButtonDisabled({ ...createBenefitRequest, policyEmploymentGroupIds: [...ids] });
  };

  const getSelectedEmploymentGroups = () => {
    return employmentGroupOptions.filter((option) =>
      createBenefitRequest.policyEmploymentGroupIds.includes(option.value),
    );
  };

  return (
    <Modal data-cy="create-benefit-modal" centered overlayBackground open={open} onClose={handleOnClose}>
      <Card
        className="create-benefit-modal"
        title={t('policySettings:benefits-tab.create-benefit-modal.title')}
        size="narrow"
      >
        <TextInput
          label={`${t('policySettings:benefits-tab.create-benefit-modal.name')}`}
          type="text"
          validationKey="text64"
          variant="default"
          value={createBenefitRequest.name}
          maxLength={65}
          onChange={handleBenefitName}
          data-cy="create-benefit-modal__input-name"
        />
        <div>
          <QueryWrapper query={GET_BENEFIT_TYPES} type="list">
            {(policyBenefitTypes: PolicyBenefitType[]) => {
              const benefitTypes = policyBenefitTypes.map((benefitType) => benefitType.type);
              const benefitTypeOptions = createOptions(benefitTypes, t, 'benefitTypes');
              return (
                <Select
                  label={`${t('policySettings:benefits-tab.create-benefit-modal.benefitType')}`}
                  placeholder={t('policySettings:benefits-tab.create-benefit-modal.placeholder.benefitType')}
                  options={benefitTypeOptions}
                  selected={benefitTypeOptions.find((option) => option.value === createBenefitRequest.benefitType)}
                  onChange={handleBenefitTypeChange}
                  data-cy="create-benefit-modal__dropdown-benefit-type"
                  className="create-benefit-modal__dropdown"
                  small
                  mandatory
                  outlined
                />
              );
            }}
          </QueryWrapper>
        </div>
        <div>
          <Select
            label={`${t('policySettings:benefits-tab.create-benefit-modal.employment-group')}`}
            placeholder={t('policySettings:benefits-tab.create-benefit-modal.placeholder.employmentGroup')}
            options={employmentGroupOptions}
            multiple
            selected={getSelectedEmploymentGroups()}
            onChange={handleEmploymentGroupChange}
            data-cy="create-benefit-modal__dropdown-employment-group"
            className="create-benefit-modal__dropdown"
            small
            mandatory
            outlined
          />
        </div>
        <CardAction>
          <CardAction>
            <Button
              label={t('common:cancel')}
              type="link"
              onClick={handleOnClose}
              data-cy="create-benefit-modal__cancel-button"
            />
            <Button
              label={t('common:create')}
              disabled={createButtonDisabled}
              onClick={handleCreateBenefit}
              data-cy="create-benefit-modal__create-button"
            />
          </CardAction>
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateBenefitModal;
