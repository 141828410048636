import { Option } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';

export const useMonths = () => {
  const { t } = useTranslation();
  return [
    t('common:months.JANUARY'),
    t('common:months.FEBRUARY'),
    t('common:months.MARCH'),
    t('common:months.APRIL'),
    t('common:months.MAY'),
    t('common:months.JUNE'),
    t('common:months.JULY'),
    t('common:months.AUGUST'),
    t('common:months.SEPTEMBER'),
    t('common:months.OCTOBER'),
    t('common:months.NOVEMBER'),
    t('common:months.DECEMBER'),
  ];
};

export const getMonthKeys = () => {
  return [
    'common:months.JANUARY',
    'common:months.FEBRUARY',
    'common:months.MARCH',
    'common:months.APRIL',
    'common:months.MAY',
    'common:months.JUNE',
    'common:months.JULY',
    'common:months.AUGUST',
    'common:months.SEPTEMBER',
    'common:months.OCTOBER',
    'common:months.NOVEMBER',
    'common:months.DECEMBER',
  ];
};
export const useMonthOptions = (withNullOption = false): Option<Month>[] => {
  const { t } = useTranslation();
  if (withNullOption === true) {
    return [
      { label: t('common:month'), value: null },
      { label: t('common:months.JANUARY'), value: 'JANUARY' },
      { label: t('common:months.FEBRUARY'), value: 'FEBRUARY' },
      { label: t('common:months.MARCH'), value: 'MARCH' },
      { label: t('common:months.APRIL'), value: 'APRIL' },
      { label: t('common:months.MAY'), value: 'MAY' },
      { label: t('common:months.JUNE'), value: 'JUNE' },
      { label: t('common:months.JULY'), value: 'JULY' },
      { label: t('common:months.AUGUST'), value: 'AUGUST' },
      { label: t('common:months.SEPTEMBER'), value: 'SEPTEMBER' },
      { label: t('common:months.OCTOBER'), value: 'OCTOBER' },
      { label: t('common:months.NOVEMBER'), value: 'NOVEMBER' },
      { label: t('common:months.DECEMBER'), value: 'DECEMBER' },
    ];
  } else {
    return [
      { label: t('common:months.JANUARY'), value: 'JANUARY' },
      { label: t('common:months.FEBRUARY'), value: 'FEBRUARY' },
      { label: t('common:months.MARCH'), value: 'MARCH' },
      { label: t('common:months.APRIL'), value: 'APRIL' },
      { label: t('common:months.MAY'), value: 'MAY' },
      { label: t('common:months.JUNE'), value: 'JUNE' },
      { label: t('common:months.JULY'), value: 'JULY' },
      { label: t('common:months.AUGUST'), value: 'AUGUST' },
      { label: t('common:months.SEPTEMBER'), value: 'SEPTEMBER' },
      { label: t('common:months.OCTOBER'), value: 'OCTOBER' },
      { label: t('common:months.NOVEMBER'), value: 'NOVEMBER' },
      { label: t('common:months.DECEMBER'), value: 'DECEMBER' },
    ];
  }
};

export const convertMonthNumberToEnum = (month: number): Month => {
  switch (month) {
    case 1:
      return 'JANUARY';
    case 2:
      return 'FEBRUARY';
    case 3:
      return 'MARCH';
    case 4:
      return 'APRIL';
    case 5:
      return 'MAY';
    case 6:
      return 'JUNE';
    case 7:
      return 'JULY';
    case 8:
      return 'AUGUST';
    case 9:
      return 'SEPTEMBER';
    case 10:
      return 'OCTOBER';
    case 11:
      return 'NOVEMBER';
    case 12:
      return 'DECEMBER';
  }
};

export const convertMonthEnumToNumber = (month: Month): number => {
  switch (month) {
    case 'JANUARY':
      return 1;
    case 'FEBRUARY':
      return 2;
    case 'MARCH':
      return 3;
    case 'APRIL':
      return 4;
    case 'MAY':
      return 5;
    case 'JUNE':
      return 6;
    case 'JULY':
      return 7;
    case 'AUGUST':
      return 8;
    case 'SEPTEMBER':
      return 9;
    case 'OCTOBER':
      return 10;
    case 'NOVEMBER':
      return 11;
    case 'DECEMBER':
      return 12;
  }
};

export const convertMonthNumberStringToEnum = (month: string): Month => {
  switch (month) {
    case '01':
      return 'JANUARY';
    case '02':
      return 'FEBRUARY';
    case '03':
      return 'MARCH';
    case '04':
      return 'APRIL';
    case '05':
      return 'MAY';
    case '06':
      return 'JUNE';
    case '07':
      return 'JULY';
    case '08':
      return 'AUGUST';
    case '09':
      return 'SEPTEMBER';
    case '10':
      return 'OCTOBER';
    case '11':
      return 'NOVEMBER';
    case '12':
      return 'DECEMBER';
  }
};

export const convertMonthEnumToStringWithZeroes = (month: Month): string => {
  switch (month) {
    case 'JANUARY':
      return '01';
    case 'FEBRUARY':
      return '02';
    case 'MARCH':
      return '03';
    case 'APRIL':
      return '04';
    case 'MAY':
      return '05';
    case 'JUNE':
      return '06';
    case 'JULY':
      return '07';
    case 'AUGUST':
      return '08';
    case 'SEPTEMBER':
      return '09';
    case 'OCTOBER':
      return '10';
    case 'NOVEMBER':
      return '11';
    case 'DECEMBER':
      return '12';
    default:
      return null;
  }
};

export const getMonth = (dateString: string): number => {
  const dateParts = dateString.split('-');
  return parseInt(dateParts[1]) - 1;
};
