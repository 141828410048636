import { Option } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

export const getYears = (): number[] => {
  const years: number[] = [];
  for (let i = 2000; i <= new Date().getFullYear() + 10; i++) {
    years.push(i);
  }
  return years;
};

export const getYear = (dateString: string): number => {
  const dateParts = dateString.split('-');
  return parseInt(dateParts[0]);
};

export const useYearOptions = (withNullOption = false): Option<number>[] => {
  const { t } = useTranslation();

  const years: Option<number>[] = [];
  for (let year = new Date().getFullYear() - 24; year <= new Date().getFullYear(); year++) {
    years.push({ label: year.toString(), value: year });
  }

  if (withNullOption) {
    years.push({ label: t('common:year'), value: null });
  }

  return years.reverse();
};
