import { ListGroup } from 'fgirot-k2-ui-components';
import InfoMessage from 'src/components/Common/InfoMessage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import ListProperty from 'src/components/Common/ListProperty';
import { Employee } from 'src/types/Employee';

interface MultipleEmploymentGroupsIndicationProps {
  checkpoint: EmploymentEventCheckpoint;
  employee: Employee;
}

const MultipleEmploymentGroupsIndication = ({ checkpoint }: MultipleEmploymentGroupsIndicationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ListGroup variant="inline-edit">
        {checkpoint?.additionalData?.employmentGroups?.map((group, groupIndex) => (
          <ListProperty key={groupIndex} label={`${group.employmentGroupName}`} value={``} />
        ))}
      </ListGroup>
      <ListGroup variant="inline-edit">
        <div className="multiple-employment-group-action-message">
          <InfoMessage message={t('examination:drawer.multiple-employment-groups-info-message')} />
        </div>
      </ListGroup>
    </>
  );
};

export default MultipleEmploymentGroupsIndication;
