import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineEdit, ListGroup, Select, Switch, Option } from 'fgirot-k2-ui-components';
import { AffiliationRule } from 'src/types/policy/AffiliationRule';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';
import { getDefaultRuleValue, getValidRuleValues } from '../AffiliationRuleTypeConfig';
import { Collapsible } from 'fgirot-k2-ui-components';
import { RuleGroupPropsWithType } from '../RuleGroupProps';
import './year-of-birth-limit-rule.scss';

const YearOfBirthLimitRule: React.FunctionComponent<RuleGroupPropsWithType> = ({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
  policyRuleType,
}) => {
  const { t } = useTranslation();
  const defaultRuleValue: RuleValue = getDefaultRuleValue(policyRuleType);
  const validRuleValues: RuleValue[] = getValidRuleValues(policyRuleType);

  const options: Option<RuleValueType>[] = validRuleValues.map((rv) => {
    return {
      label: t(`policyRules:ruleValues.${policyRuleType}.selectValueType.${rv.valueType}`),
      value: rv.valueType,
    };
  });

  const preSelected = () =>
    options.find((option) => {
      return (
        option.value ===
        (selectedAffiliationRuleGroup.affiliationRules.flatMap((rule) => rule.ruleValues).length > 0
          ? selectedAffiliationRuleGroup.affiliationRules[0].ruleValues[0].valueType
          : undefined)
      );
    });

  const handleSelect = (option: Option<string>) => {
    if (policyState === 'DRAFT') {
      const selectedOption = options.find((opt) => opt.value === option.value);
      const ruleValues: RuleValue[] = [validRuleValues.find((rv) => rv.valueType === selectedOption.value)];

      const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find((r) => r);
      onUpdateOrDeleteAffiliationRule({ ruleId: selectedPolicyRule?.id, policyRuleType, ruleValues });
    }
  };

  const getCurrentRule = (): AffiliationRule =>
    selectedAffiliationRuleGroup.affiliationRules.find((ruleType) => ruleType.policyRuleType === policyRuleType);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = event.target.checked ? [{ ...defaultRuleValue }] : [];
      onUpdateOrDeleteAffiliationRule({ ruleId: getCurrentRule()?.id, policyRuleType, ruleValues });
    }
  };

  return (
    <div className="rule-type-drawer__list-group">
      <ListGroup variant={'inline-edit'}>
        <InlineEdit label={t('policyRules:ruleValues.apply-rule')} bold>
          <Switch
            toggled={!!getCurrentRule()}
            onChange={(event) => handleToggle(event)}
            data-cy={`year-of-birth-limit-rule__switch-${policyRuleType}`}
          />
        </InlineEdit>
      </ListGroup>
      <Collapsible className="year-of-birth-limit-rule__collapsible" open={!!getCurrentRule()}>
        <ListGroup variant="inline-edit">
          <InlineEdit bold key="YearOfBirthLimitRule" label={t('common:year')}>
            <Select
              disabled={policyState !== 'DRAFT'}
              options={options}
              selected={preSelected()}
              onChange={handleSelect}
              data-cy={`year-of-birth-limit-rule__dropdown-${policyRuleType}`}
              small
            />
          </InlineEdit>
        </ListGroup>
      </Collapsible>
    </div>
  );
};

export default YearOfBirthLimitRule;
