import { gql } from '@apollo/client';

export const GET_REEMPLOYMENT_CHECKPOINT_STATE = gql`
  query reEmploymentCheckpointState($checkpointId: UUID!) {
    reEmploymentCheckpointState(checkpointId: $checkpointId) {
      id
      checkpointId
      isReEmployment
      newEmploymentStartDate
      chosenEventDate
      preserveHistory
      checkpointEmployeeId
    }
  }
`;
