import { gql } from '@apollo/client';

export const GET_VERIFY_INSURANCE_EVENT_CHECKPOINTS = gql`
  query GetInsuranceEventCheckpoints($employerIds: [UUID]!, $wageFilePeriod: String!) {
    reportEmployees(
      employerIds: $employerIds
      wageFilePeriod: $wageFilePeriod
      actionCategory: "VERIFY_INSURANCE_EVENT"
    ) {
      employee {
        id
        firstName
        lastName
        personNumber
        employerId
        employmentStartDate
        employmentEndDate
        previousEmploymentEndDate
        hasDraft
      }
      employeeCard {
        id
        employeeId
        employmentGroupId
        workingPercentage
        sickDays
      }
      checkpoints {
        id
        errandId
        employerId
        subActionCategory
        checkpointStatus
        benefitType
        insuranceEventType
        additionalData {
          employeeCardId
          employeeCardPeriod
          insuranceEventId
          eventFlowType
          insuranceProviderId
          insuranceProviderContractId
          insuranceProviderProductConditionId
        }
      }
    }
  }
`;
