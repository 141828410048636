import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import { useCreateBalancingOfBooksSettings } from 'src/service/accountingunit/BalancingOfBooksSettings';
import { Month } from 'src/types/Month';
import { CreateBalancingOfBooksSettingsInput } from 'src/types/accountingunit/BalancingOfBooksSettings';
import { useMonthOptions } from 'src/util/date/Months';

interface AddBalancingOfBooksSettingsModalProps {
  open: boolean;
  onClose: () => void;
}
const AddBalancingOfBooksSettingsModal: FC<AddBalancingOfBooksSettingsModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [yearEndClosingMonth, setYearEndClosingMonth] = useState<Month>('DECEMBER');
  const [lastEndYearClosing, setLastEndYearClosing] = useState<string>('');
  const [dayOfMonth, setDayOfMonth] = useState<number>(31);
  const createBalancingOfBooksSettings = useCreateBalancingOfBooksSettings();

  const handleDayOfMonthChange = (e: ChangeEvent<HTMLInputElement>) => setDayOfMonth(e.target.valueAsNumber);
  const handleYearEndClosingMonthChange = (option: Option<Month>) => setYearEndClosingMonth(option.value);
  const handleLastEndYearClosingChange = (value: string) => {
    setLastEndYearClosing(value);
  };

  const monthOptions = useMonthOptions();

  const handleModalClose = () => {
    onClose();
  };

  const handleCreateBalancingOfBookSettings = () => {
    const request: CreateBalancingOfBooksSettingsInput = {
      lastEndYearClosing: lastEndYearClosing,
      yearEndClosingMonth: yearEndClosingMonth,
      dayOfMonth,
    };
    createBalancingOfBooksSettings(request, employerId).then(handleModalClose);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleModalClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.title')}
        defaultClose
        onClose={handleModalClose}
      >
        <div className="accounting-settings-tab__card-content-row">
          <UpdateDatePicker
            fieldValue={lastEndYearClosing}
            fieldKey="lastEndYearClosingDate"
            label={t(
              'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.last-end-year-closing-label',
            )}
            placeholder="ÅÅÅÅ-MM-DD"
            onBlur={(_, value) => handleLastEndYearClosingChange(value)}
          />
        </div>

        <div className="accounting-settings-tab__card-content-row">
          <Select
            label={t(
              'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.year-end-closing-month',
            )}
            options={monthOptions}
            alphabetical={false}
            selected={monthOptions.find((option) => option.value === yearEndClosingMonth)}
            onChange={handleYearEndClosingMonthChange}
          />
        </div>
        <TextInput
          type="number"
          variant="default"
          label={t(
            'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.day-of-month-label',
          )}
          validationKey="integer"
          value={dayOfMonth.toString()}
          onChange={handleDayOfMonthChange}
        />
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleModalClose}
            data-cy="create-policy-modal__cancel-button"
          />
          <Button
            label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.add')}
            onClick={handleCreateBalancingOfBookSettings}
            data-cy="create-policy-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddBalancingOfBooksSettingsModal;
