import {
  Button,
  Checkbox,
  Collapsible,
  Icon,
  InlineEdit,
  ListGroup,
  Select,
  Tooltip,
  Typography,
} from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import When from 'src/components/Common/When';
import InfoMessage from 'src/components/Common/InfoMessage';
import {
  ReEmploymentCheckpointState,
  UpsertReEmploymentCheckpointStateRequest,
} from 'src/types/reporting/ReEmploymentCheckpointState';
import { Employee } from 'src/types/Employee';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { useUpsertReEmploymentCheckpointState } from 'src/service/reporting/UpsertReEmploymentCheckpointState';
import './update-reemployment.scss';
import { formatDateStringToYearMonth } from 'src/util/date/PeriodUtil';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import { getYear } from 'src/util/date/Years';
import { getMonth } from 'src/util/date/Months';

interface UpdateReemploymentProps {
  employee: Employee;
  checkpoint: EmploymentEventCheckpoint;
  employerId: string;
  checkpointState: ReEmploymentCheckpointState;
  onBackClick: () => void;
}

const UpdateReemployment: React.FC<UpdateReemploymentProps> = ({
  checkpoint,
  employee,
  employerId,
  checkpointState,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedReemploymentOption, setSelectedReemploymentOption] = useState<boolean | undefined>(
    checkpointState?.isReEmployment,
  );
  const [selectedStartDate, setSelectedStartDate] = useState<string>(
    checkpointState?.newEmploymentStartDate || employee.employmentStartDate,
  );
  const [chosenEventDate, setChosenEventDate] = useState<string>(checkpointState?.chosenEventDate || null);
  const [chosenEventDateError, setChosenEventDateError] = useState<string>(undefined);
  const [keepHistory, setKeepHistory] = useState<boolean | undefined>(checkpointState?.preserveHistory);

  const upsertReEmploymentCheckpointState = useUpsertReEmploymentCheckpointState();

  const allowedToSuggestEventDate =
    employee.employmentEndDate === null && selectedStartDate === employee.employmentStartDate;

  const reEmploymentOptions = [
    { label: t('common:yes'), value: true },
    { label: t('common:no'), value: false },
  ];

  const isButtonDisabled: boolean = undefined === selectedReemploymentOption || chosenEventDateError !== undefined;

  useEffect(() => {
    if (checkpointState) {
      setSelectedReemploymentOption(checkpointState.isReEmployment);
      setSelectedStartDate(checkpointState.newEmploymentStartDate || employee.employmentStartDate);
      setChosenEventDate(checkpointState.chosenEventDate);
      setKeepHistory(checkpointState.preserveHistory);
    }
  }, [checkpointState]);

  const handleReemploymentOptionChange = (value: boolean) => {
    if (!value) {
      setSelectedStartDate('');
      setKeepHistory(false);
      setChosenEventDate('');
    }
    setSelectedReemploymentOption(value);
  };

  const handleOnChange = (__field: string, value: string) => {
    setSelectedStartDate(value);
    if (value !== employee.employmentStartDate) {
      setChosenEventDate(null);
    }
  };

  const chosenEventDateChanged = (__field: string, value: string) => {
    setChosenEventDate(value);
    if (value && value <= employee.previousEmploymentEndDate) {
      setChosenEventDateError(
        t('examination:drawer.chosen-event-date-error:date-before-previous-end', {
          previousEnd: employee.previousEmploymentEndDate,
        }),
      );
    } else if (value && formatDateStringToYearMonth(value).replace('-', '') > checkpoint.wageFilePeriod) {
      setChosenEventDateError(
        t('examination:drawer.chosen-event-date-error:date-after-period', {
          period: checkpoint.wageFilePeriod,
        }),
      );
    } else {
      setChosenEventDateError(undefined);
    }
  };

  const handleCheckboxUpdate = (checked: boolean) => {
    setKeepHistory(checked);
  };

  const handleSubmit = () => {
    const request: UpsertReEmploymentCheckpointStateRequest = {
      checkpointId: checkpoint.id,
      isReEmployment: selectedReemploymentOption ?? false,
      newEmploymentStartDate: selectedReemploymentOption ? selectedStartDate || null : null,
      chosenEventDate: selectedReemploymentOption ? chosenEventDate : null,
      preserveHistory: selectedReemploymentOption ? keepHistory ?? null : null,
      checkpointEmployeeId: employee.id,
    };

    setLoading(true);
    upsertReEmploymentCheckpointState(request, employerId)
      .then(() => {
        onBackClick();
      })
      .finally(() => setLoading(false));
  };

  const getYearFromPreviousEmploymentEndDate = (employee: Employee) => {
    return employee.previousEmploymentEndDate ? getYear(employee.previousEmploymentEndDate) : undefined;
  };

  const getMonthFromPreviousEmploymentEndDate = (employee: Employee) => {
    return employee.previousEmploymentEndDate ? getMonth(employee.previousEmploymentEndDate) : undefined;
  };

  return (
    <div className="update-reemployment">
      <ListGroup variant="inline-edit" className="update-reemployment">
        <InlineEdit label={t('examination:drawer.is-reemployment-title')} bold>
          <Select
            small
            placeholder={t('common:select')}
            options={reEmploymentOptions}
            selected={reEmploymentOptions.find((option) => option.value === selectedReemploymentOption)}
            onChange={(option) => handleReemploymentOptionChange(option.value)}
            allowDropdownMaxWidth={false}
            disabled={loading}
          />
        </InlineEdit>
      </ListGroup>

      <Collapsible open={selectedReemploymentOption === true}>
        <ListGroup variant="inline-edit">
          <When condition={selectedReemploymentOption === true}>
            <InlineEdit bold label={t('examination:drawer.reemployment-start-date')}>
              <UpdateDatePicker
                fieldKey="employmentStartDate"
                onBlur={handleOnChange}
                fieldValue={selectedStartDate}
                initialYear={selectedStartDate ? getYear(selectedStartDate) : undefined}
                initialMonth={selectedStartDate ? getMonth(selectedStartDate) : undefined}
                disabled={loading}
              />
            </InlineEdit>
            <When condition={allowedToSuggestEventDate}>
              <InlineEdit
                label={
                  <>
                    <Typography className="update-reemployment__label" bold component="span" variant="body2">
                      {t('examination:drawer.chosen-event-date')}
                    </Typography>
                    <Tooltip content={t('examination:drawer:chosen-event-date-tooltip')} direction="bottom">
                      <Icon className="update-reemplotment__info-icon" type="Info" />
                    </Tooltip>
                  </>
                }
              >
                <UpdateDatePicker
                  fieldKey="chosenEventDate"
                  onBlur={chosenEventDateChanged}
                  fieldValue={chosenEventDate}
                  initialYear={selectedStartDate ? getYearFromPreviousEmploymentEndDate(employee) : undefined}
                  initialMonth={selectedStartDate ? getMonthFromPreviousEmploymentEndDate(employee) : undefined}
                />
              </InlineEdit>
            </When>
            <InlineEdit bold label={t('examination:drawer.keep-history-checkbox')}>
              <Checkbox
                checked={keepHistory}
                onChange={(e) => handleCheckboxUpdate(e.target.checked)}
                disabled={loading}
              />
            </InlineEdit>
          </When>
        </ListGroup>
      </Collapsible>

      {selectedReemploymentOption === false && (
        <InfoMessage message={t('examination:drawer.no-reemployment-message')} />
      )}
      {chosenEventDateError && <ErrorMessage message={chosenEventDateError} />}
      <Button
        className="update-reemployment__save-button"
        label={t('common:save')}
        disabled={isButtonDisabled}
        onClick={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default UpdateReemployment;
