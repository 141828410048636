import { gql } from '@apollo/client';

export const CURRENT_FINANCIAL_YEAR = gql`
  query GetCurrentFinancialYear($employerId: UUID!) {
    currentFinancialYear(employerId: $employerId) {
      fromDate
      toDate
    }
  }
`;
