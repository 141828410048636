import { Button, Drawer, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import TextInput from 'src/components/Common/TextInput';
import { useCreatePaymentTransaction } from 'src/service/invoice/PaymentTransaction';
import { CreatePaymentTransactionInput } from 'src/types/invoice/CreatePaymentTransactionInput';
import { Invoice } from 'src/types/invoice/Invoice';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { isBlank } from 'src/util/StringUtil';
import { LARGE_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import './create-new-payment-transaction.scss';

interface CreateNewPaymentTransactionProps {
  invoice: Invoice;
  onClose: () => void;
  onBackClick: () => void;
}

const CreateNewPaymentTransaction: FC<CreateNewPaymentTransactionProps> = ({ invoice, onClose, onBackClick }) => {
  const { t } = useTranslation();
  const createPaymentTransaction = useCreatePaymentTransaction();
  const [paymentTransactionInput, setPaymentTransactionInput] = useState<CreatePaymentTransactionInput>({
    amount: 0,
    note: '',
  });

  const [amountInput, setAmountInput] = useState<string>('0,00');

  const [calculatedRemainingAmount, setcalculatedRemainingAmount] = useState<number>(
    invoice.remainingAmount < 0
      ? invoice.remainingAmount + paymentTransactionInput.amount
      : invoice.remainingAmount - paymentTransactionInput.amount,
  );

  const amountWithTwoDecRegex = /^-?[0-9\s]{1,11}(,\d{1,2})?$/;

  const isCreateButtonDisabled =
    isBlank(amountInput || paymentTransactionInput?.note) ||
    paymentTransactionInput.note.length < 3 ||
    amountInput.length === 0 ||
    paymentTransactionInput.amount === 0 ||
    !amountWithTwoDecRegex.test(amountInput) ||
    (invoice.remainingAmount < 0 && !amountInput.startsWith('-'));

  const handleAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace('.', ',');
    let formattedValue = inputValue;

    if (invoice.remainingAmount < 0) {
      if (!formattedValue.startsWith('-')) {
        formattedValue = `-${formattedValue}`;
      }

      const numericValue = parseFloat(formattedValue.replace(',', '.')) || 0;

      if (numericValue > 0) {
        return;
      }

      const maxCredit = Math.abs(invoice.remainingAmount);
      if (Math.abs(numericValue) > maxCredit) {
        formattedValue = `-${maxCredit.toFixed(2).replace('.', ',')}`;
      }
    } else {
      const numericValue = parseFloat(formattedValue.replace(',', '.')) || 0;

      if (numericValue > invoice.remainingAmount) {
        formattedValue = `${invoice.remainingAmount.toFixed(2).replace('.', ',')}`;
      }
    }

    setAmountInput(formattedValue);
  };

  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPaymentTransactionInput({ ...paymentTransactionInput, note: e.target.value });
  };

  const handleBlur = () => {
    const numericValue = parseFloat(amountInput.replace(',', '.'));

    if (!isNaN(numericValue)) {
      let adjustedAmount = numericValue;

      if (invoice.remainingAmount < 0) {
        adjustedAmount = Math.max(numericValue, -Math.abs(invoice.remainingAmount));
      } else {
        adjustedAmount = Math.min(numericValue, invoice.remainingAmount);
      }

      setPaymentTransactionInput({
        ...paymentTransactionInput,
        amount: adjustedAmount,
      });

      handleTriggerDisplayRemainingAmount(adjustedAmount);
    }
  };

  const handleTriggerDisplayRemainingAmount = (amount: number) => {
    setcalculatedRemainingAmount(invoice.remainingAmount - amount);
  };

  const handleCreateNewTransaction = () => {
    createPaymentTransaction(invoice.id, paymentTransactionInput, invoice.employer.id).then(() => onBackClick());
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('invoice:invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>

      <div className="create-new-payment-transaction">
        <Typography variant="body1" bold>
          {t('invoice:invoice-drawer.transactions-tab.add-new-transaction-title')}
        </Typography>
      </div>

      <div className="create-new-payment-transaction__list-group">
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('invoice:invoice-drawer.transactions-tab.amount-label')} bold mandatory>
            <TextInput
              placeholder={
                amountInput === '0,00' ? '' : t('invoice:invoice-drawer.transactions-tab.amount-placeholder')
              }
              validationKey={invoice.remainingAmount < 0 ? 'amount2DecAllowNegative' : 'amount2Dec'}
              type="text"
              variant="inline-edit"
              value={amountInput}
              onChange={handleAmountInput}
              onBlur={handleBlur}
              onFocus={() => setAmountInput(amountInput === '0,00' ? '' : amountInput)}
            />
          </InlineEdit>
          <InlineEdit label={t('invoice:invoice-drawer.transactions-tab.remaining-amount-label')} bold>
            <Typography variant="body2">
              {formatSweAmountText(`${!calculatedRemainingAmount ? 0 : calculatedRemainingAmount}`)}
            </Typography>
          </InlineEdit>
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('invoice:invoice-drawer.transactions-tab.note-label')} bold mandatory>
            <div className="create-new-payment-transaction__note-input">
              <TextArea
                small
                value={paymentTransactionInput?.note}
                onChange={handleNoteInput}
                validationKey="text1000"
                placeholder={t('invoice:invoice-drawer.transactions-tab.note-placeholder')}
                characterLimit={LARGE_TEXTAREA_MAX_CHARACTERS}
              />
            </div>
          </InlineEdit>
        </ListGroup>
      </div>
      <div className="payment-transactions-details__buttons">
        <Button
          className="payment-transactions-details__button"
          label={t('common:add')}
          onClick={handleCreateNewTransaction}
          disabled={isCreateButtonDisabled}
          data-cy="create-new-payment-transaction__add-button"
        />
      </div>
    </Drawer>
  );
};

export default CreateNewPaymentTransaction;
