import React, { ChangeEvent } from 'react';
import { RuleGroupPropsWithType } from '../../RuleGroupProps';
import { getDefaultRuleValue, getValidValueTypes } from '../../AffiliationRuleTypeConfig';
import { RuleValue, RuleValueType } from 'src/types/policy/RuleValue';
import { Collapsible, InlineEdit, ListGroup, Select, Switch } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Option } from 'fgirot-k2-ui-components';
import './individual-choice-itp1-rule.scss';

function IndividualChoiceITP1Rule({
  policyState,
  selectedAffiliationRuleGroup,
  onUpdateOrDeleteAffiliationRule,
  policyRuleType,
}: RuleGroupPropsWithType) {
  const { t } = useTranslation();

  const validValueTypes: RuleValueType[] = getValidValueTypes(policyRuleType);
  const defaultValue: RuleValue = getDefaultRuleValue(policyRuleType);

  const selectedPolicyRule = selectedAffiliationRuleGroup.affiliationRules.find(
    (rule) => rule.policyRuleType === policyRuleType,
  );

  const valueTypeOptions: Option<RuleValueType>[] = validValueTypes.map((vt) => {
    return {
      label: t(`policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.valueType.${vt}`),
      value: vt,
    };
  });

  const handleSelectValueType = (option: Option<RuleValueType>) => {
    if (policyState === 'DRAFT') {
      const newOption = option.value as RuleValueType;
      const ruleValues: RuleValue[] = [{ value: null, valueType: newOption }];
      onUpdateOrDeleteAffiliationRule({ ruleId: selectedPolicyRule?.id, policyRuleType, ruleValues });
    }
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (policyState === 'DRAFT') {
      const ruleValues: RuleValue[] = event.target.checked ? [{ ...defaultValue }] : [];

      onUpdateOrDeleteAffiliationRule({
        ruleId: selectedPolicyRule?.id,
        policyRuleType,
        ruleValues,
      });
    }
  };

  const getCurrentSelected = (): Option<RuleValueType> => {
    return valueTypeOptions.find((option) => option.value === selectedPolicyRule?.ruleValues[0]?.valueType);
  };

  return (
    <>
      <ListGroup variant={'inline-edit'}>
        <InlineEdit bold label={t(`policyRules:policyRuleType.${policyRuleType}`)}>
          <Switch toggled={!!selectedPolicyRule} onChange={(event) => handleToggle(event)} />
        </InlineEdit>
      </ListGroup>
      <Collapsible className="induvidual-choice-itp1-rule__collapsible" open={!!selectedPolicyRule}>
        <ListGroup variant="inline-edit">
          <InlineEdit
            bold
            label={t(
              `policyRules:ruleTypeGroup.${selectedAffiliationRuleGroup.affiliationRuleGroupType}.value-type-label`,
            )}
          >
            <Select
              disabled={policyState !== 'DRAFT'}
              options={valueTypeOptions}
              selected={getCurrentSelected()}
              onChange={handleSelectValueType}
              small
            />
          </InlineEdit>
        </ListGroup>
      </Collapsible>
    </>
  );
}

export default IndividualChoiceITP1Rule;
