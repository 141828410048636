import { Button, Card, CardAction, Divider, ErrorSection, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WageFileSession } from 'src/types/WageFileSession';
import './validation-error-modal.scss';

interface ValidationErrorModalProps {
  open: boolean;
  onClose?: () => void;
  session?: WageFileSession;
}

const ValidationErrorModal = ({ open, onClose, session }: ValidationErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <div className={['validation-error-modal'].join(' ')}>
      <Modal overlayBackground centered open={open} onClose={onClose}>
        <Card
          defaultClose
          icon={<Icon type="Alert" className="validation-error-modal__icon" />}
          title={t('upload:reporting-status.company-upload-status.validation-error.file-process-error')}
          subtitle={`${t(
            'upload:reporting-status.company-upload-status.validation-error.file-cant-be-processed-before',
          )} ${session?.filename} ${t(
            'upload:reporting-status.company-upload-status.validation-error.file-cant-be-processed-after',
          )}`}
          onClose={onClose}
          error
        >
          <ErrorSection className="validation-error-modal__error-section">
            {session &&
              session.validationErrors.map((error, index) => (
                <Typography key={`${session.id}-${error.errorMessageKey}-${index}`}>
                  {t(`errors:${error.errorMessageKey}`)}
                </Typography>
              ))}
          </ErrorSection>
          <CardAction>
            <Button type="link" label={t('common:cancel')} onClick={onClose} />
            <Button
              label={t('upload:reporting-status.company-upload-status.validation-error.remove-file')}
              icon={<Icon type="Trash" />}
            />
          </CardAction>
          <Divider className="validation-error-modal__divider" />
          <Typography variant="body1" component="span">
            <>
              {t('upload:reporting-status.company-upload-status.validation-error.contact-support-before-email')}
              <a href="mailto:kund@fgirot.se">kund@fgirot.se</a>
              {t('upload:reporting-status.company-upload-status.validation-error.contact-support-after-email')}
            </>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
};

export default ValidationErrorModal;
